<template>
    <div>
        <div class="app flex-row align-items-center" v-if="flights.length!== 0">
            <div class="container-fluid" style="z-index:2" v-for="flight in flights.slice(0,1)" :key="flight.id">


            <b-card no-body style="margin-bottom: 10px; margin-top: 10px">
                <p style="text-align: center; font-size: 145px; margin: 0; line-height: normal">
                    <strong>Gate {{ flight.gate }}</strong>
                </p>
            </b-card>


            <b-card-group style="height: 56vh">

                <b-card no-body class="p-4" style="margin-right: 6px; margin-bottom: 10px">
                    <blank style=" font-size: 60px"><strong>Flight:</strong></blank>
                    <blank style="text-align: center; font-size: 120px; line-height: 80px">{{ flight.flight }}</blank>
                    <span style="text-align: center; color: grey; font-size: 40px">{{ flight.status }}</span>
                    <blank style=" font-size: 60px"><strong>Departing:</strong></blank>
                    <blank style="text-align: center; font-size: 2.65vw">
                        {{ location.name }} ({{ flight.departing }})
                        at {{ flight.departuretime.slice((flight.departuretime).indexOf(" ")) }}
                    </blank>
                    <blank style=" font-size: 60px; margin: 0"><strong>Arriving:</strong></blank>
                    <blank style="text-align: center; font-size: 2.65vw">
                    {{ destination.name }} ({{ flight.arriving }})
                    at {{ flight.arrivaltime.slice((flight.arrivaltime).indexOf(" ")) }}
                    </blank>
                </b-card>

                <b-card no-body class="text-white bg-primary py-5 d-md-down-none"
                      style="width:44%; margin-left: 6px; margin-bottom: 10px">
                    <b-card-body class="text-center">
                        <span style="display: flex; align-items: baseline; justify-content: center; height: inherit">
                            <img class="correctCenter" style="padding: 10px" :src="mapAirlineLogo(flight.airline)" width="100%" alt="">
                        </span>
                    </b-card-body>
                </b-card>

            </b-card-group>


            <b-card style="height: 275px; margin-bottom: 10px">
                <div style="display: flex; flex-direction: row">
                    <div style="flex: 1; display: flex;flex-direction: column">
                        <h1 style="text-align: center">Current Time</h1>
                        <h1 style="text-align: center; font-size: 750%"><strong>{{ liveTimeData }}</strong></h1>
                        <h3 style="text-align: center; color: grey">Departure {{ fromnowify(flight.departuretime) }}</h3>
                    </div>
                    <div style="flex: 1; display: flex; flex-direction: column">
                        <h1 style="font-size: 45px; float: top; text-align: center">
                            <strong>{{ citify(location.city) }}, {{ location.state }}</strong>
                        </h1>
                        <strong v-if="locationWeather.main !== undefined" style="display: flex; justify-content: center">
                            <span style="font-size: 125px; line-height: 105px">{{ tempify(locationWeather.main.temp) }}</span>
                            <span style="font-size: 30px; ">&deg;F</span>
                            <img style="height: 150px" :src="'/img/weather/' + locationWeather.weather[0].icon + '.png'" alt=""/>
                        </strong>
                        <h1 v-else style="text-align: center; margin-top: 45px"><span>No Weather Data To Display</span></h1>
                        <p v-if="locationWeather.main !== undefined"
                            style="text-align: center; font-size: 30px; line-height: 0">
                            {{ locationWeather.weather[0].description.capitalize() }}
                        </p>
                    </div>
                    <fa-icon :icon="['fas', 'right-long']" size="5x" style="margin-top: 85px"/>
                    <div style="flex: 1; display: flex; flex-direction: column">
                        <h1 style="font-size: 45px; float: top; text-align: center">
                            <strong>{{ citify(destination.city) }}, {{ destination.state }}</strong>
                        </h1>
                        <strong v-if="destinationWeather.main !== undefined" style="display: flex; justify-content: center">
                            <span style="font-size: 125px; line-height: 105px">{{ tempify(destinationWeather.main.temp) }}</span>
                            <span style="font-size: 30px; ">&deg;F</span>
                            <img style="height: 150px" :src="'/img/weather/' + destinationWeather.weather[0].icon + '.png'" alt=""/>
                        </strong>
                        <h1 v-else style="text-align: center; margin-top: 45px"><span>No Weather Data To Display</span></h1>
                        <p v-if="destinationWeather.main !== undefined"
                            style="text-align: center; font-size: 30px; line-height: 0">
                            {{ destinationWeather.weather[0].description.capitalize() }}
                        </p>
                    </div>
                </div>
            </b-card>


          </div>
        </div>

        <div v-else class="app flex-row align-items-center" id="particles-js">
            <b-form style="width: 100%; z-index: 999">
                <img style=" display: block;margin-left: auto; margin-right: auto"
                    src="../../../../public/img/mtoplogo2.png" width="80%" alt="">
                <h1 class="display-3 text-center"><small><b>{{ time }}</b></small></h1>
            </b-form>
        </div>

  </div>
</template>

<script>
import fcr from '../../../services/fcr.js'
import axios from "axios";
import '../../../../node_modules/particles.js/particles';
import * as moment from 'moment';
import blackhole from "@/services/blackhole";

export default {

    name: 'Login',

    updated() {
    },

    data() {
        return {
            liveTimeData: null,
            departureFromNow: null,
            flights: [],
            time: "",
            airlines: [],
            location: {},
            destination: {},
            locationWeather: {},
            destinationWeather: {},
            apikeys: ["a134956f6f07e4eb61904a7c416179f9", "25c2ec79db68a17139bf39339b9b5645", "803ad287f62712d4a8b9ddbb501daf97", "04eb2c7a60e2aa42f4ddbb38a261a6bb"]
        }
    },
    created() {
        fcr.readAirlines().then(response => {
            this.airlines = response.data;
        });
        this.getFlightInfo();
        setInterval(() => {
            this.getFlightInfo()
        }, 15e3);
        this.liveTime();
    },

    mounted() {
        this.initParticles();
    },

    computed: {
        screenid: function () {
            return this.$route.params.id
        }
    },

    methods: {
        liveTime() {
            this.liveTimeData = moment(new Date(), 'x').format('h:mm A');
            setTimeout(this.liveTime, 500);
        },
        mapAirlineLogo(airlineName) {
            for (let airline of this.airlines) {
                if (airline.name === airlineName) {
                    return this.logo(airline.image);
                }
            }
        },
        fromnowify(date) {
            return (moment(date).fromNow().capitalize());
        },
        logo(logoId) {
            if (!logoId) {
                return ""
            } else {
                return blackhole.readImage(logoId);
            }
        },
        initParticles() {
            window.particlesJS("particles-js", {
                "particles": {
                    "number": {
                        "value": 80,
                        "density": {
                            "enable": true,
                            "value_area": 700
                        }
                    },
                    "color": {
                        "value": "#ffffff"
                    },
                    "shape": {
                        "type": "circle",
                        "stroke": {
                            "width": 0,
                            "color": "#000000"
                        },
                        "polygon": {
                            "nb_sides": 5
                        },
                    },
                    "opacity": {
                        "value": 0.5,
                        "random": false,
                        "anim": {
                            "enable": false,
                            "speed": 1,
                            "opacity_min": 0.1,
                            "sync": false
                        }
                    },
                    "size": {
                        "value": 5,
                        "random": true,
                        "anim": {
                            "enable": false,
                            "speed": 40,
                            "size_min": 0.1,
                            "sync": false
                        }
                    },
                    "line_linked": {
                        "enable": true,
                        "distance": 150,
                        "color": "#ffffff",
                        "opacity": 0.4,
                        "width": 1
                    },
                    "move": {
                        "enable": true,
                        "speed": 6,
                        "direction": "none",
                        "random": false,
                        "straight": false,
                        "out_mode": "out",
                        "bounce": false,
                        "attract": {
                            "enable": false,
                            "rotateX": 600,
                            "rotateY": 1200
                        }
                    }
                },
                "interactivity": {
                    "detect_on": "canvas",
                    "events": {
                        "onhover": {
                            "enable": false,
                            "mode": "grab"
                        },
                        "onclick": {
                            "enable": true,
                            "mode": "push"
                        },
                        "resize": true
                    },
                    "modes": {
                        "grab": {
                            "distance": 140,
                            "line_linked": {
                                "opacity": 1
                            }
                        },
                        "bubble": {
                            "distance": 400,
                            "size": 40,
                            "duration": 2,
                            "opacity": 8,
                            "speed": 3
                        },
                        "repulse": {
                            "distance": 200,
                            "duration": 0.4
                        },
                        "push": {
                            "particles_nb": 4
                        },
                        "remove": {
                            "particles_nb": 2
                        }
                    }
                },
                "retina_detect": true
            })
        },
        getFlightInfo() {
            this.time = moment(Date.now(), 'x').format('llll');
            let currentTime = new Date().getTime();
            fcr.readFlights().then(response => {
                for (let i = response.data.length - 1; i >= 0; i--) {
                    if (currentTime < parseInt(response.data[i].rollIn) || currentTime >= parseInt(response.data[i].rollOut) || response.data[i].gate !== this.screenid) {
                        response.data.splice(i, 1)
                    }
                }
                this.flights = response.data;
                fcr.readAirportInfo(response.data[0].arriving).then(response => {
                    this.destination = response.data;
                    axios({
                        method: 'get',
                        url: "https://api.openweathermap.org/data/2.5/weather?lat=" + this.destination.latitude + "&lon=" + this.destination.longitude + "&appid=" + this.apikeys[Math.floor(Math.random() * this.apikeys.length)]
                    }).then(response => {
                        this.destinationWeather = response.data
                    });
                });
                fcr.readAirportInfo(response.data[0].departing).then(response => {
                    this.location = response.data;
                    axios({
                        method: 'get',
                        url: "https://api.openweathermap.org/data/2.5/weather?lat=" + this.location.latitude + "&lon=" + this.location.longitude + "&appid=" + this.apikeys[Math.floor(Math.random() * this.apikeys.length)]
                    }).then(response => {
                        this.locationWeather = response.data
                    });
                });
            });
        },
        tempify(kelvin) {
            let final = 9 / 5 * (kelvin - 273) + 32;
            return (parseInt(final).toFixed())
        },
        citify(city) {
            if (city === 'Chicago/Rockford') {
                return ('Rockford')
            } else {
                return (city)
            }
        }
    },

}

String.prototype.capitalize = function () {
    return this.replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
    });
};

</script>

<style>

#particles-js {
    background: #2f353a;
}

canvas {
    position: absolute;
    z-index: 1;
}

.correctCenter {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    width: 100%;
}

* {
    line-height: 1.1;
}
</style>

